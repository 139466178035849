import React, { useState } from "react";

import { Register } from '../StatelessComponent/register';

export const RegisterMain = () => {
    const[user, setUser] = useState('');
    const[email, setEmail] = useState('');
    const[phone, setPhone] = useState('');
    const[pass, setPass] = useState('');
    const[data, setData] = useState({});
    const[ buttonText, setButtonText ] = useState('Sign Up');

    const handleUser = (e) => {
        e.preventDefault();
        setUser(e.target.value);
    };

    const handleEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    const handlePhone = (e) => {
        e.preventDefault();
        setPhone(e.target.value);
    };

    const handlePass = (e) => {
        e.preventDefault();
        setPass(e.target.value);
    };

    const handleLogin = async () => {
        try {

            setButtonText('Signing Up...');
            const url = 'http://localhost:5000/api/auth/signup';

            const req = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({ username: user, email, phone, pass }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await req.json();
            console.log(res);
            if (res.status === 200) {
                console.log(res);
            };

            setData(res);
            setButtonText('Sign Up');
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Register user={user}
            email={email}
            phone={phone}
            pass={pass}
            data={data}
            buttonText={buttonText}
            handleUser={handleUser}
            handleEmail={handleEmail}
            handlePhone={handlePhone}
            handlePass={handlePass}
            handleLogin={handleLogin}
        />
    );
};