import React, { useState } from "react";
import { Login } from "../StatelessComponent/login";

export const MainLogin = () => {
    const[ user, setUser ] = useState('');
    const[ pass, setPass ] = useState('');
    const[ data, setData ] = useState({});
    const[ buttonText, setButtonText ] = useState('Sign In');

    const handleUser = (e) => {
        e.preventDefault();
        setUser(e.target.value);
    };

    const handlePass = (e) => {
        e.preventDefault();
        setPass(e.target.value);
    };

    const handleLogin = async () => {
        try {

            setButtonText('Signing In...');
            const url = 'http://localhost:5000/api/auth/login';

            const req = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({ email: user, pass }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await req.json();
            console.log(res.status);
            // console.log(res);

            if (req.status !== 200) {

                // console.log(res);
                setButtonText('Sign In');
                return alert(res.msg);
            };

            setData(res);
            localStorage.setItem('token', res.token);
            setButtonText('Sign In');
            return document.location.href = '/order';
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     handleLogin();
    // }, []);

    return (
        <Login user={user}
            pass={pass}
            data={data}
            handleUser={handleUser}
            handlePass={handlePass}
            handleLogin={handleLogin}
            buttonText={buttonText}
        />
    );
};