import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import logo from './logo.svg';
// import './App.css';
import { MainLogin } from './StatefulComponent/Login';
import { RegisterMain } from "./StatefulComponent/Register";
import OrderForm from "./StatefulComponent/Order";
import ProductMain from "./StatefulComponent/Product";
import Upload from "./StatefulComponent/Upload";
import OrderConfirm from "./StatefulComponent/orderConfirm";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' Component={MainLogin} />
          <Route path='/login' Component={MainLogin} />
          <Route path='/register' Component={RegisterMain} />
          <Route path='/order' Component={OrderForm} />
          <Route path='/verify' Component={ProductMain} />
          <Route path='upload' Component={Upload} />
          <Route path='/order-confirm' Component={OrderConfirm} />
      </Routes> 
    </Router>
  );
}

export default App;
