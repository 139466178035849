import React, { useState, useEffect } from "react";

import FormConfirm from "../StatelessComponent/formConfirm";
import fetchData from '../scripts/data';

const OrderConfirm = () => {
    // Change the background color if its in order url
    const pathUrl = document.location.pathname;
    if (pathUrl === '/order-confirm') {
        const body = document.body;
        console.log('Order URL')
        body.style.backgroundColor = '#E19400'
    };

    const [user, setUser] = useState({});
    const [reName, setReName] = useState('');
    const [rePh, setRePh] = useState('');
    const [reAdd, setReAdd] = useState('');

    const handleReName = (e) => {
        e.preventDefault();
        setReName(e.target.value);
    };

    const handleRePh = (e) => {
        e.preventDefault();
        setRePh(e.target.value);
    };

    const handleReAdd = (e) => {
        e.preventDefault();
        setReAdd(e.target.value);
    };

    useEffect(() => {
        fetchData(setUser);
    }, []);

    return (
        <div>
            <FormConfirm
                user={user}
                reName={reName}
                rePh={rePh}
                reAdd={reAdd}
                handleReName={handleReName}
                handleRePh={handleRePh}
                handleReAdd={handleReAdd}
            />
        </div>
    );
};

export default OrderConfirm;