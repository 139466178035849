import React from 'react';

import Product from '../StatelessComponent/product';
import Data from '../scripts/data';

const ProductMain = () => {
    Data();
    return (
        <Product />
    );
};

export default ProductMain;