import React from "react";

import '../css/login.css';

export const Register = ({
    user,
    email,
    phone,
    pass,
    buttonText,
    handleUser,
    handleEmail,
    handlePhone,
    handlePass,
    handleLogin,
}) => {
    return (
        <div className="lg-body">
            <div className="lg-main">
                <div className="hd">
                    <h1 className="hd-h">Sign Up</h1>
                    <p className="hd-p">Create your account now and start enjoying fast, secure, and hassle-free deliveries</p>
                </div>
                <div className="card-form card-form1">
                    <div>
                        <form>
                            <input placeholder="Full Name" className="in in1" value={user || ''} onChange={handleUser}/>
                            <input placeholder="Email" className="in" value={email} onChange={handleEmail}/>
                            <input placeholder="Phone Number" className="in" value={phone} onChange={handlePhone}/>
                            <input placeholder="Password" className="in" value={pass} onChange={handlePass}/>
                        </form>
                    </div>
                    <div>
                        <button className="in bt" onClick={handleLogin}>{buttonText}</button>
                    </div>
                    <div className="agree-terms">
                        <input type="checkbox" className="agree-box"/>
                        <p className="agree-text">I have read and agreed to the terms & conditions.</p>
                    </div>
                    <div style={{ marginTop: '20px', fontWeight: '700' }}>
                        <p className="fr-p">Already have an account? <a href='/'>Sign In</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};