import React, { useRef, useState } from "react";
import Webcam from 'react-webcam';

const CustomWebCam = () => {
    const webcamRef = useRef(null);
    const [ imgUrl, setImgUrl ] = useState(null);
    const [currentViewIndex, setCurrentViewIndex] = useState(0);
    const view = [ 'Front', 'Back', 'Left', 'Right' ];
    const [isRetake, setIsRetake] = useState(false);
    // let [storage, setStorage] = useState({
    //     front: false,
    //     back: false,
    //     left: false,
    //     right: false,
    // });


    const storeImg = (url) => {

        if (url === undefined) {
            return console.log('No URL Present');
        };

        if (currentViewIndex >= view.length) {
            alert('Please Upload Picture');
            return console.log('All Views has been stored');
        }

        sessionStorage.setItem(view[currentViewIndex], url);

        console.log(currentViewIndex);

        return setCurrentViewIndex(prev =>  prev + 1);
    };

    // const checkStorage = (views) => {
    //     const front = sessionStorage.getItem('Front')
    //     const back = sessionStorage.getItem('Back')
    //     const left = sessionStorage.getItem('Left')
    //     const right = sessionStorage.getItem('Right')

    //     if (front === null) {
    //         setStorage({
    //             front: false,
    //         });
    //     } else {
    //         setStorage({
    //             front: true,
    //         });
    //     }

    //     if (back === null) {
    //         setStorage({
    //             back: false,
    //         });
    //     } else {
    //         setStorage({
    //         left: true,
    //         });
    //     }

    //     if (left === null) {
    //         setStorage({
    //             left: false,
    //         });
    //     } else {
    //         setStorage({
    //             left: true,
    //         });
    //     }

    //     if (right === null) {
    //         setStorage({
    //             right: false,
    //         });
    //     } else {
    //         setStorage({
    //             right: true,
    //         });
    //     }

        
    //     // for (let i = 0; i < views.length; i++) {
    //     //     const element = views[i];

    //     //     // console.log(element);

    //     //     const view = sessionStorage.getItem(element);

    //     //     if (view[3] !== null) {
    //     //         setStorage(true);
    //     //         return alert('Something Went Wrong. Please Click on Cancel and try again');
    //     //     }

    //     //     console.log(view);
            
    //     // }

    //     // console.log(storage);

    //     // return storage;
    // };

    // console.log(currentViewIndex);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
            storeImg(imageSrc);
            setImgUrl(imageSrc);
        // checkStorage(view);
        // setStorage(false);

        // Check if Storage is True;
        // if (!storage) {
        //     setImgUrl(null);
        //     return alert('Something Went Wrong');
        // } else {
        //     const imageSrc = webcamRef.current.getScreenshot();
        //     storeImg(imageSrc);
        //     setImgUrl(imageSrc);
        // }
        // console.log(imgUrl)
      }

      const videoConstraints = {
        facingMode: "environment"
      };

      const retake = () => {
        if (currentViewIndex >= view.length) {
            setIsRetake(true);
            sessionStorage.setItem('done', true);
            alert('Please Upload Picture');
            return console.log('All Views has been stored');
        }
        // checkStorage(view)
        setImgUrl(null)
      };

      if (imgUrl !== null) {
        return (
            <div>
                <img src={imgUrl} alt="" style={{ width: '100%' }}/>
                <button onClick={retake} className={isRetake ? 'in': 'in bt'} disabled={isRetake ? true: false}>Retake</button>
            </div>
        );
      } else {
        return (
            <div>
                <Webcam 
                    height={282} 
                    width={376}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    onCapture={capture}
                />
                <button className='in bt' onClick={capture}>Capture {`${view[currentViewIndex]} View`}</button>
            </div>
        );
      };
};

export default CustomWebCam;