import React from 'react';

import '../css/product.css';
import demoImage from '../Images/Rectangle 1.png';
import CustomWebCam from './webCam';

const Product = ({ url, handleUpload }) => {

    const retake = sessionStorage.getItem('done');
    const isRetake = Boolean(retake);

    console.log(isRetake);

    if (url === '/upload') {
        return (
            <div className='product'>
            <div className='img-section'>
                <p className='view-txt'>Take images of the product ordered</p>
                <div>
                    <CustomWebCam style={{width: '100%'}} />
                </div>
            </div>
            <div className='view'>
                <div>
                    <p className='view-pos'>Top View</p>
                </div>
                <p className='view-pos'>Right View</p>
                <p className='view-pos'>Left View</p>
                <p className='view-pos'>Back View</p>
            </div>
            <div style={{marginTop: '70px'}}>
                <button className={isRetake ? 'in bt' : 'in'} disabled={isRetake ? true : false} onClick={handleUpload}>Upload</button>
            </div>
        </div>
        );
    }
    return (
        <div className='product'>
            <div className='img-section'>
                <p className='view-txt'>View live images of product ordered</p>
                <div>
                    <img src={demoImage} className='img-pr' alt=''/>
                </div>
            </div>
            <div className='view'>
                <p className='view-pos'>Top View</p>
                <p className='view-pos'>Right View</p>
                <p className='view-pos'>Left View</p>
                <p className='view-pos'>Back View</p>
            </div>
            <div style={{marginTop: '70px'}}>
                <button className="in bt">Confirm</button>
            </div>
        </div>
    );
};

export default Product;