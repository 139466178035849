import React, { useEffect, useState } from "react";

import Product from "../StatelessComponent/product";

// Import Scripts
import Data from '../scripts/data';

const Upload = () => {
    const url = document.location.pathname;
    const [imgArr, setImgArr] = useState([]);
    // const front = sessionStorage.getItem('Front');
    // const back = sessionStorage.getItem('Back');
    // const left = sessionStorage.getItem('left');
    // const right = sessionStorage.getItem('right');

    // const imgFiles = [front, back, left, right]

    const handleUpload = async () => {

        console.log('Working');

        const front = sessionStorage.getItem('Front');
        const back = sessionStorage.getItem('Back');
        const left = sessionStorage.getItem('left');
        const right = sessionStorage.getItem('right');

        setImgArr([front, back, left, right]);

        const url = 'http://localhost:5000/api/auth/signup';

        const request = await fetch(url, {
            method: 'POST',
            body: imgArr,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            }
        });

        const response = await request.json();

        console.log(response);
    };

    // Data();

    useEffect(() => {
        Data();
    }, [])
    return (
        <Product url={url}
            handleUpload={handleUpload}
         />
    );
};

export default Upload;