import React, { useState, useEffect } from 'react';


import Form from '../StatelessComponent/form';
import fetchData from '../scripts/data';

const OrderForm = () => {
    // Change the background color if its in order url
    const pathUrl = document.location.pathname;
    if (pathUrl === '/order') {
        const body = document.body;
        console.log('Order URL')
        body.style.backgroundColor = '#E19400'
    }


    const [user, setUser] = useState({});
    const [pul, setPul] = useState('');
    const [sn, setSn] = useState('');
    const [sp, setSp] = useState('');
    const [itemD, setItemD] = useState('');
    // const [details, setDetails] = useState({});

    const handlePul = (e) => {
        e.preventDefault();
        setPul(e.target.value);
    };

    const handleSn = (e) => {
        e.preventDefault();
        setSn(e.target.value);
    }

    const handleSp = (e) => {
        e.preventDefault();
        setSp(e.target.value);
    };

    const handleItemD = (e) => {
        e.preventDefault();
        setItemD(e.target.value);
    };

    const handleButton = () => {
        // Write the logic here

       const details = { pul, sn, sp, itemD }

        console.log(details);

        localStorage.setItem('details', JSON.stringify(details));

        return document.location.href = '/order-confirm';
    };

    useEffect(() => {
        fetchData(setUser);
    }, []);

    return (
        <div>
            <Form user={user}
                pul={pul}
                sn={sn}
                sp={sp}
                itemD={itemD}
                handlePul={handlePul}
                handleSn={handleSn}
                handleSp={handleSp}
                handleItemD={handleItemD}
                handleButton={handleButton}
             />
        </div>
    );
};

export default OrderForm;
