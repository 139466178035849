import React from 'react';

import '../css/form.css';
// import '../css/login.css';
import orderImage from '../Images/orderImage.svg';

const form = ({ pul, sn, sp, itemD, handlePul, handleSn, handleSp, handleItemD, handleButton }) => {
    return (
        <div className='main'>
            <div className='orderSection'>
                <h3 className='orderText'>What are you ordering?</h3>
                <img className='orderImage' src={orderImage} alt='' />
            </div>
            <div className='card'>
                <div className='container'>
                    <input className='or-in' value={pul} onChange={handlePul} required/>
                    <label>Pick Up Location</label>
                </div>
                <div className='container'>
                    <input className='or-in' value={sn} onChange={handleSn} required/>
                    <label>Seller's Name</label>
                </div>
                <div className='container'>
                    <input className='or-in' value={sp} onChange={handleSp} required/>
                    <label>Seller's Phone Number</label>
                </div>
                <div className='container'>
                    <input id='textarea'className='or-in' value={itemD} onChange={handleItemD} required/>
                    <label>Item description</label>
                </div>
                <div>
                    <button className="in bt" onClick={handleButton}>NEXT</button>
                </div>
            </div>
        </div>
    );
};
export default form;