const fetchData = async (setUser) => {
    try {
        const url = 'http://localhost:5000/api/';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'auth-token': localStorage.getItem('token'),
            }
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            document.location.href = '/login';
            return;
        }

        const { name, email, phone } = responseData.msg;

        setUser({ name, email, phone });
        console.log({ name, email, phone });

    } catch (error) {
        alert(`${error}. Please Check your internet connection and try again`);
        console.error(error);
    }
};

export default fetchData;
